:root {
  touch-action: pan-x pan-y;
  height: 100% 
}

#root {
  height: 100%;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.login_loader {
  background-image: url("images/DiscGolfSmall.jpg");
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  height: 100%;
}

body {
  /* background-color:#f5f5f5; */

  height: 100%;
}

a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87)
}

h2 {
  text-align: center;
}