.standings-button {
  width: 100%;
  margin: 10px 0 5px 0 !important;
}

.mega-number {
  font-size: 5em;
  margin: 33px 0;
}

.game-controller {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
