.number-input {
  width: 40px;
  font-weight: bold;
  background-color: white;
}

.button-group>button {
  border: none !important;
  border-radius: 50%;
}
.button-group>div {
  border: none !important;
}
.dirty-score {
  color: green !important;
  font-weight: bolder;
}

.button-group {
  display: inline-flex;
}
.button-group > button {
  min-width: 40px;
}
